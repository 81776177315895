export const QUESTIONS = ['aisha', 'panic library', 'loveless', 'kezia']

export const TEXT = ['PANIC360', 'CLICK ANYWHERE']

export const STATUS = {
  INITIALIZE: 0,
  INSTRUCTION: 1,
  START: 2,
  SUCCESS: 3,
  HIDE: 4,
}
